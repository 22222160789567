import "design-system/app.scss";

import React, {
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import Search from "design-system/search/Search";
import { AppDataContext } from "design-system/shared/contexts/app-data/AppDataContext";
import { UiStateContext } from "design-system/shared/contexts/ui-state/UiStateContext";
import useContentStack from "design-system/shared/hooks/use-content-stack/useContentStack";
import {
  AppDataModel,
  FeatureToggle,
  PhSiteSettings,
} from "design-system/shared/models/app-data-model";
import ConfirmDialog from "design-system/shared/components/modal/modal";
import { navigate } from "@reach/router";

export type AppProps = {
  children: ReactNode;
  data?: any;
};

/**
 * This component controls our entire App Layout for PH.com
 *
 * @param {AppProps} { children }
 * @returns
 */
const App: React.FC<AppProps> = ({ children }) => {
  const context = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const [speedbumpOpen, setSpeedbumpOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [speedbumpMessage, setSpeedbumpMessage] = useState('Are you sure you want to leave the Priority Health website?');
  //let message = 'Are you sure you want to leave the Priority Health website?';

  const latestFeatureToggles: FeatureToggle[] = (
    useContentStack([{ contentType: 'site_settings', entryUid: 'blte616382108d9c26a' }])?.responses?.find(
      (r) => r.content
    )?.content as PhSiteSettings
  )?.feature_toggles;

  /**
   * This useEffect updates our featureToggles if they have changed during runtime
   * but haven't been updated in our static builds yet
   */
  useEffect(() => {
    if (context.setAppData && latestFeatureToggles !== context.appData?.globalSettings?.feature_toggles) {
      const globalSettings: PhSiteSettings = {
        feature_toggles: latestFeatureToggles,
        site_alert: context.appData.globalSettings?.site_alert,
        search_settings: context.appData.globalSettings?.search_settings,
        login_dropdown_items: context.appData?.globalSettings?.login_dropdown_items,
        speedbump_settings: context.appData.globalSettings?.speedbump_settings // TODO: Convert this to be static first
      };

      const appData: AppDataModel = {
        main_navigation: context.appData.main_navigation,
        globalSettings: globalSettings
      };

      context.setAppData(appData);
    }
    // we can't depend on context or we endless loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestFeatureToggles]);

  /* istanbul ignore next */
  useEffect(() => {
    const isFromMedicarePage = isFromMedicareRoute(window.location.href);
    const speedbumps = context.appData.globalSettings?.speedbump_settings;
    const whitelist = speedbumps?.whitelist ?? [];
    setSpeedbumpMessage(speedbumps?.external_speedbump_content);
    if (isFromMedicarePage)
      setSpeedbumpMessage(speedbumps?.medicare_speedbump_content);

    // Select anchor tags, with exclusions
    const links = window.document.querySelectorAll('a:not([data-ph-link]):not([href^="tel:"]):not([href^="mailto:"]):not([href^="sms:"])');
    links.forEach((link) =>
      (link as HTMLAnchorElement).addEventListener('click', handleLinkClick)
    );

    return () => {
      links.forEach((link) =>
        (link as HTMLAnchorElement).removeEventListener('click', handleLinkClick)
      )
    }

    function handleLinkClick(event: MouseEvent) {
      const link = event.currentTarget as HTMLAnchorElement;
      // Need to get raw href attribute from element
      const attrHref = link.getAttribute('href');
      // Avoid complaints about href possibly being null
      const href = attrHref ?? '';
      const isToMedicarePage = isToMedicareRoute(href);
      const isUrlWhitelisted = isOnWhitelist(href, whitelist);

      const showSpeedbump = isFromMedicarePage && !(isToMedicarePage || isUrlWhitelisted);
      if (showSpeedbump) {
        event.preventDefault();
        setLinkUrl(href);
        setSpeedbumpOpen(true);
      } else {
        event.preventDefault();
        openLink(href);
      }
    }

    function isFromMedicareRoute(url: string) {
      return url?.toLowerCase().indexOf("medicare") > 0
    }

    function isToMedicareRoute(url: string) {
      return url.startsWith('/') && url?.toLowerCase().indexOf("medicare") > 0
    }

    function isOnWhitelist(url: string, whitelist: string[]) {
      if(url.startsWith('/')) return false;

      const targetHostUrl = tryGetHostname(url);
      if (searchStringInArray(targetHostUrl, whitelist) >= 0)
        return true;

      return false;
    }

    function tryGetHostname(url: string) {
      try {
        return new URL(url).hostname;
      } catch {
        return '';
      }
    }

    function searchStringInArray(str: string, strArray: string[]) {
      for (let j = 0; j < strArray.length; j++) {
        if (strArray[j].match(str)) return j;
      }
      return -1;
    }
  }, [context]);

  /* istanbul ignore next */
  function openLink(url: string) {
    if (url.startsWith('/')) {
      navigate(url);
    } else {
      window.open(url, "_blank", "noreferrer");
    }
  }

  /* istanbul ignore next */
  function onConfirmClose(result: boolean) {
    setSpeedbumpOpen(false);

    if (result) {
      openLink(linkUrl);
      setLinkUrl('');
    }
  }

  return <>
    {uiStateContext.searchOpen ? <Search featureToggles={latestFeatureToggles} /> : children}
    {speedbumpOpen && (
      <ConfirmDialog
        open={speedbumpOpen}
        title=""
        content={speedbumpMessage}
        onClose={(result: boolean) => onConfirmClose(result)}
      />
    )}
  </>;
};

export default App;
