import "./ph-link.scss";

import React, {
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";

import { Link } from "gatsby";

import ButtonMui from "@mui/material/Button";
import { navigate } from "@reach/router";

import { AppDataContext } from "../../contexts/app-data/AppDataContext";
import useReferenceUrl from "../../hooks/reference-url/useReferenceUrl";
import {
  BreadCrumb,
  Reference,
  SimpleReference
} from "../../models/app-data-model";
import { getBreadCrumbData } from "../../services/breadcrumb-generator";
import ConfirmDialog from "../modal/modal";

export type crumb = {
  title: string,
  url: string
}

export type buttonVariant = 'text' | 'contained' | 'outlined';

function isButtonType(value: string): value is buttonVariant {
  return ['text', 'contained', 'outlined'].includes(value);
}

export type PhLinkProps = {
  className?: string;
  label?: string;
  reference?: Reference[];
  href?: string,
  link?: Link[],
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  sx?: { display: string; alignItems: string; }
};

function searchStringInArray(str: string, strArray: string[]) {
  for (let j = 0; j < strArray.length; j++) {
    if (strArray[j].match(str)) return j;
  }
  return -1;
}

// checks the breadcrumb data for Medicare
function searchStringInBreadCrumbArray(str: string, breadCrumbData: BreadCrumb[]) {
  const cleanBreadCrumbData = breadCrumbData.filter(breadcrumb => breadcrumb?.title !== undefined);
  const index = cleanBreadCrumbData.findIndex(Breadcrumb => Breadcrumb.title === str);
  if (index >= 0)
    return true;
  else
    return false;
}

function hrefToRef(href: string): SimpleReference | undefined {
  if (href.startsWith("/")) {
    return {
      href,
      type: 'ph_internal_link',
    };
  }

  return {
    href,
    type: 'ph_external_link',
  };
}

function tryGetHostname(url: string) {
  try {
    return new URL(url).hostname;
  } catch {
    return '';
  }
}

function openInNewTab(url: string | URL | undefined) {
  window.open(url, "_blank", "noreferrer");
};

function openInSameTab(url: string | URL) {
  navigate(url.toString());
};

/**
 * The PhLink component, which handles whether to render a <a> tag or a Gatsbyjs <Link>
 * @param param0 the PhLinkProps
 * @returns A component which renders either a <a> tag, or <Link>, depending on if the link is internal or external;
 *  if the type can't be determined, it will render nothing.
 *
 * what we have to deal with are:
 * buttons with external links
 * buttons with internal links
 * links with external links
 * links with internal links
 *
 * external links that are under the medicare portion of the site
 * external links that are not under the medicare portion of the site
 * external links that are in the whitelist
 * external links that are not in the whitelist
 **/

const PhLink: React.FC<PhLinkProps> = ({
  label,
  className,
  reference,
  href,
  link,
  size,
  children,
}: PhLinkProps) => {

  // is this coming from the Medicare section of the site -
  // it has a Medicare breadcrumb?
  const [isMedicare, setIsMedicare] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  // is this a button (or a link? <a></a>)
  // const isButton = contentType === 'button';
  const buttonVariant = className?.toLowerCase() as buttonVariant;
  const isButton = isButtonType(buttonVariant);

  // see if is Medicare
  useEffect(() => {
    const breadCrumbData = getBreadCrumbData(window.location.href);
    setIsMedicare(searchStringInBreadCrumbArray("Medicare", breadCrumbData));
  }, [isMedicare]);

  let isInternalPage = false;
  let isExternalPage = false;

  // get the reference URL & type
  const ref = useReferenceUrl(reference);
  if (reference) {

    // link is internal or external
    isInternalPage = ref?.type === "ph_internal_link";
    isExternalPage = ref?.type === "ph_external_link";

    href = (ref as SimpleReference)?.href;
  }

  if (link && link[0]?.href)
    href = link[0]?.href;

  if (href) {
    const hrefRef = hrefToRef(href);

    isInternalPage = hrefRef?.type === "ph_internal_link";
    isExternalPage = hrefRef?.type === "ph_external_link";
  }

  // if target is external, and the url doesn't include http or https,
  // add https to the url.
  if (href && !href.includes("http") && isExternalPage)
    href = "https://" + href.trim();

  const url: string | URL = href !== undefined ? href : "";

  const isToMedicare = isInternalPage && url.toLowerCase().indexOf("medicare") > 0;

  // get the speedbump data (message content and white list)
  const appDataContext = useContext(AppDataContext);
  const speedbumps = appDataContext.appData.globalSettings?.speedbump_settings;

  // check if target host url is in the whitelist
  // internal links are whitelisted
  let urlWhitelisted = false;

  // the target host is the url without http or https
  // used to compare against the white list
  const targetHostUrl = url !== undefined && isExternalPage ? tryGetHostname(url) as string : "";
  const whitelist = speedbumps?.whitelist != undefined ? speedbumps.whitelist : [] as string[];
  if (searchStringInArray(targetHostUrl, whitelist) >= 0 || isInternalPage)
    urlWhitelisted = true;

  // determine the modal message - currently assume either
  // medicare or just leaving the PH web site
  let message = speedbumps?.external_speedbump_content;
  if (isMedicare)
    message = speedbumps?.medicare_speedbump_content;

  // creates the internal link. There is probably a way to do this
  // as a button rather than a link (maybe), but more research is needed.
  // the internalLink should be a common occurrence

  // INTERNAL LINK
  // NOT A BUTTON
  // NOT MEDICARE
  let internalLink = isInternalPage && !isButton && (
    <Link
      data-ph-link
      className={className}
      to={url}
      activeClassName="active"
    >
      {children}
      {label}
    </Link>
  );

  // INTERNAL LINK
  // NOT A BUTTON
  // IS MEDICARE
  if (isInternalPage && !isButton && isMedicare) {
    if (isToMedicare) {
      // internal destination is medicare, don't show pop up
      internalLink = (
        <Link
          data-ph-link
          className={className}
          to={url}
          activeClassName="active"
        >
          {children}
          {label}
        </Link>
      );
    } else {
      // internal destination is not medicare, show pop up
      internalLink = (
        <>
          <Link
            data-ph-link
            className={className}
            to={url}
            activeClassName="active"
            onClick={(event) => {event.preventDefault(); setDialogOpen(true)}}
          >
            {children}
            {label}
          </Link>
          {dialogOpen && (
            <ConfirmDialog
              open={dialogOpen}
              title=""
              content={message}
              onClose={(result: boolean) => {
               setDialogOpen(false);
                if (result) {
                  openInSameTab(url);
                }
              }}
            />
          )}
        </>
      );
    }
  }

  // INTERNAL LINK
  // IS A BUTTON
  // NOT MEDICARE
  if (isInternalPage && isButton && !isMedicare) {
    internalLink = (
      <ButtonMui
        data-ph-link
        component={Link}
        to={url as string}
        variant={buttonVariant}
        size={size}
      >
        {children}
        {label}
      </ButtonMui>
    );
  }

  // INTERNAL LINK
  // IS A BUTTON
  // IS MEDICARE
  if (isInternalPage && isButton && isMedicare) {
    // if destination contains medicare
    if (isToMedicare) {
      // internal destination is medicare, don't show pop up
      internalLink = (
        <ButtonMui
          data-ph-link
          component={Link}
          to={url as string}
          variant={buttonVariant}
          size={size}
        >
          {children}
          {label}
        </ButtonMui>
      );
    }
    else {
      // internal destination is not medicare, show pop up
      internalLink = (
        <>
          <ButtonMui
            data-ph-link
            component={Link}
            to={url}
            variant={buttonVariant}
            size={size}
            onClick={(event) => {event.preventDefault(); setDialogOpen(true)}}
          >
            {children}
            {label}
          </ButtonMui>
          {dialogOpen && (
            <ConfirmDialog
              open={dialogOpen}
              title=""
              content={message}
              onClose={(result: boolean) => {
               setDialogOpen(false);
                if (result) {
                  openInSameTab(url);
                }
              }}
            />
          )}
        </>
      );
    }
  }

  // creates the external link.
  // this is a whitelisted, external link as a button

  // EXTERNAL LINK
  // IS A BUTTON
  // IS WHITELISTED
  let externalLink = isExternalPage && urlWhitelisted && isButton && (
    <ButtonMui
      data-ph-link
      href={url}
      variant={buttonVariant}
      size={size}
      onClick={(event) => {event.preventDefault(); openInNewTab(href)}}
    >
      {children}
      {label}
    </ButtonMui>
  );

  // EXTERNAL LINK
  // IS A BUTTON
  // NOT WHITELISTED
  if (!urlWhitelisted && isExternalPage && isButton) {

    // create the modal
    // should work as a button or a url (<a></a>)
    // NOTE: I'm not sure if the URL will close the modal (need to test)
    externalLink = (
      <>
        <ButtonMui
          data-ph-link
          href={url}
          variant={buttonVariant}
          size={size}
          onClick={(event) => {event.preventDefault(); setDialogOpen(true); }}
        >
          {children}
          {label}
        </ButtonMui>
        {dialogOpen && (
          <ConfirmDialog
            open={dialogOpen}
            title=""
            content={message}
            onClose={(result: boolean) => {
             setDialogOpen(false);
              if (result) {
                openInNewTab(url);
              }
            }}
          />
        )}
      </>
    );
  }

  // EXTERNAL LINK
  // IS NOT A BUTTON
  if (isExternalPage && !isButton) {
    if (isMedicare && !urlWhitelisted) {
      externalLink = (<>
        <a
          data-ph-link
          className={className}
          href={url}
          onClick={(event) => {event.preventDefault(); setDialogOpen(true)}}
        >
          {children}
          {label}
        </a>
        {dialogOpen && (
          <ConfirmDialog
            open={dialogOpen}
            title=""
            content={message}
            onClose={(result: boolean) => {
             setDialogOpen(false);
              if (result) {
                openInNewTab(url);
              }
            }}
          />
        )}
      </>);
    } else {
      externalLink = (
        <a data-ph-link href={url} target="_blank">{children}{label}</a>
      );
    }
  }

  // return the link/button
  return (
    <>
      {isInternalPage && internalLink}
      {isExternalPage && externalLink}
    </>
  );
};

export default PhLink;
